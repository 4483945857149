import Vue from 'vue'
import VueRouter from 'vue-router'
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

// 手机端判断
if (flag) {
  var Home = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/mobile/pages/home.vue')
  var News = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/mobile/pages/news.vue')
  var NewsDetail = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/mobile/pages/news_detail.vue')
  var Privacy = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/mobile/pages/privacy.vue')
  var Jubao = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/mobile/pages/jubao.vue')
  var CallUs = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/mobile/pages/call_us.vue')
} else {
  var Home = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pc/pages/home.vue')
  var News = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pc/pages/news.vue')
  var NewsDetail = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pc/pages/news_detail.vue')
  var Privacy = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pc/pages/privacy.vue')
  var Jubao = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pc/pages/jubao.vue')
  var CallUs = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pc/pages/call_us.vue')
}



const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

console.log(flag)

const routes = [
  { path: '*', redirect: '/home' },
  { path: '/', redirect: '/home' },
  // 重定向Welcome页面
  { path: '/home', component: Home, meta: {title: '首页-LUCKY'} },
  { path: '/news', component: News, meta: {title: '新闻资讯-LUCKY'} },
  { path: '/news_detail', component: NewsDetail, meta: {title: '新闻详情-LUCKY'} },
  { path: '/privacy', component: Privacy, meta: {title: '隐私政策-LUCKY'} },
  { path: '/jubao', component: Jubao, meta: {title: '廉政举报-LUCKY'} },
  { path: '/call_us', component: CallUs, meta: {title: '联系我们-LUCKY'} },
]

const router = new VueRouter({
  routes
})

// router.addRoutes(routerArr)

// <!--挂载路由导航守卫,控制登录页面获取token值跳转页面-->

router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // feom 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  // next() 表示放行   next('/login') 表示强制跳转登录页面

  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'LCUKY官网'
  }

  // if (to.path === '/login') return next();
  // // 获取token
  // const tokenStr = window.localStorage.getItem('token');
  // if (!tokenStr) return next('/login')
  next();
})

export default router
