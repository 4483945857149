import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElmentUI from 'element-ui' // 全局引入element
import 'element-ui/lib/theme-chalk/index.css' // 全局引入element样式

import axios from '@/utils/axios'
import '@/utils/rem.js'
import 'swiper/css/swiper.css'

Vue.config.productionTip = false


Vue.prototype.$axios = axios

import "video.js/dist/video-js.css"; // 引入video.js的css
import 'vue-video-player/src/custom-theme.css'
import VideoPlayer from 'vue-video-player/src'

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
Vue.use(VideoPlayer)
Vue.use(ElmentUI) // 全局注册elememntui

import Tools from '@/utils/tools'
Vue.use(Tools)
// 为了解决setItemEvent不立即生效，使用事件派发强制更高
Tools.dispatchEventStroage()
Tools.dispatchEventStroage_S()

window.addEventListener('setItemEvent', function (e) {
  })


Vue.mixin({
	data() {
		return {
		}
	},
	mounted() {
		this.isMobiles()
	},
  methods: {
	  isMobiles() {
		let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		Vue.prototype.isMobile = flag // 用$与组件内自定义的函数区分，其他符号也可以
	},
	  // 时间戳格式化
		formatDate(time, type) {
			let date = new Date(time * 1000);
			//时间戳为10位需*1000，时间戳为13位的话不需乘1000
			let y = date.getFullYear();
			let MM = date.getMonth() + 1;
			MM = MM < 10 ? ('0' + MM) : MM; //月补0
			let d = date.getDate();
			d = d < 10 ? ('0' + d) : d; //天补0
			let h = date.getHours();
			h = h < 10 ? ('0' + h) : h; //小时补0
			let m = date.getMinutes();
			m = m < 10 ? ('0' + m) : m; //分钟补0
			let s = date.getSeconds();
			s = s < 10 ? ('0' + s) : s; //秒补0
			if (type == 2) {
				return y + '-' + MM + '-' + d + ' ' + h + ':' + m; //年月日
			} else {
				return y + '-' + MM + '-' + d; //年月日
			}
		},
		// 名字托敏
		setName(nameStr) {
			if (!nameStr) {
				return ''
			} else if (nameStr.length == 2) {
				return nameStr.substr(0, 1) + '*'
			} else if (nameStr.length >= 3) {
				let ls = ''
				for (let i = 0; i <= nameStr.length - 3; i++) {
					ls += '*'
				}
				return nameStr.substr(0, 1) + ls + nameStr.substr(-1, 1)
			} else {
				return '*'
			}
		},
		// 身份证脱敏
		setCard(str) {
			if (str) {
				return str.substr(0, 3) + '*************' + str.substr(-2);
			} else {
				return '';
			}
		},
		// 手机号脱敏
		setCardNo(str) {
			if (str) {
				return str.substr(0, 3) + '****' + str.substr(-3);
			} else {
				return '';
			}
		},
		// 电话脱敏
		setMobile(str) {
			if (str) {
				if (str.length == 2) {
					return str.substr(0, 1) + '*'
				} else if (2 < str.length && str.length <= 7) {
					let ls = ''
					for (let i = 0; i <= str.length - 3; i++) {
						ls += '*'
					}
					return str.substr(0, 1) + ls + str.substr(-1, 1)
				} else if (str.length > 7) {
					let ls = ''
					for (let i = 0; i <= str.length - 7; i++) {
						ls += '*'
					}
					return str.substr(0, 3) + ls + str.substr(-3)
				} else {
					return '*'
				}
			} else {
				return '';
			}
		},
    // 出生日期码校验
		checkDate(val) {
			var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
			if (pattern.test(val)) {
				var year = val.substring(0, 4);
				var month = val.substring(4, 6);
				var date = val.substring(6, 8);
				var date2 = new Date(year + "-" + month + "-" + date);
				if (date2 && date2.getMonth() == (parseInt(month) - 1)) {
					return true;
				}
			}
			return false;
		},
		// 校验码校验
		checkCode(val) {
			var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
			var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
			var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
			var code = val.substring(17);
			if (p.test(val)) {
				var sum = 0;
				for (var i = 0; i < 17; i++) {
					sum += val[i] * factor[i];
				}
				if (parity[sum % 11] == code.toUpperCase()) {
					return true;
				}
			}
			return false;
		},
		// 省级地址码校验
		checkProv(val) {
			var pattern = /^[1-9][0-9]/;
			var provs = {
				11: "北京",
				12: "天津",
				13: "河北",
				14: "山西",
				15: "内蒙古",
				21: "辽宁",
				22: "吉林",
				23: "黑龙江 ",
				31: "上海",
				32: "江苏",
				33: "浙江",
				34: "安徽",
				35: "福建",
				36: "江西",
				37: "山东",
				41: "河南",
				42: "湖北 ",
				43: "湖南",
				44: "广东",
				45: "广西",
				46: "海南",
				50: "重庆",
				51: "四川",
				52: "贵州",
				53: "云南",
				54: "西藏 ",
				61: "陕西",
				62: "甘肃",
				63: "青海",
				64: "宁夏",
				65: "新疆",
				71: "台湾",
				81: "香港",
				82: "澳门"
			};
			if (pattern.test(val)) {
				if (provs[val]) {
					return true;
				}
			}
			return false;
		},
		// 身份证正则验证
		checkIDCard(val) {
			if (this.checkCode(val)) {
				var date = val.substring(6, 14);
				if (this.checkDate(date)) {
					if (this.checkProv(val.substring(0, 2))) {
						return true;
					}
				}
			}
			return false;
		},
		// 邮箱正则表达
		checkEmail(val) {
			let reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/
			return reg.test(val)
		},
  }
})

new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
	Vue.prototype.$bus = this
},
}).$mount('#app')
